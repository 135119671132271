import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import CustomForm from "../components/CustomForm/CustomForm";
import { useCallback } from "react";
import * as Msal from "msal";
import { Navigate, useNavigate } from "react-router-dom";
import MyContext from "../utils/contexts/MyContext";
import { useLocation } from "react-router-dom";
import { onNewsletterClickCT, onUserLoginCT } from "../utils/clevertap";
import { media } from "../utils/media";

const SignIn = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const { treeData, setTreeData } = React.useContext(MyContext);
  const [formData, setFormData] = useState({});
  const fields = [
    {
      name: "Email Address",
      type: "email",
      style: { backgroundColor: "white" },
    }, // Customize 'email' field background color
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (window.localStorage.getItem("loginStatus") === "true") {
      console.log("Already logged in, redirecting...");
      navigate("/redirect");
    }
  }, []);

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const msalConfig = {
    auth: {
      clientId: "9a7ffe59-718e-40ee-b04e-d6f85b53f512",
      authority:
        "https://login.microsoftonline.com/00a9ff8c-9830-4847-ae51-4579ec092cb4",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };

  const msalInstance = new Msal.UserAgentApplication(msalConfig);

  const handleSubmit = () => {
    // Send the formData to the API or perform further processing here
    console.log(formData);
    let email = formData["Email Address"];
    window.localStorage.setItem("email", email);
    const isInternalUser = email.endsWith("@redseerconsulting.com");
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/authorise/login/?email=${email}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        // this.props.userLogin(data.token);
        if (data["otp_access"] === true) {
          setLoggedIn(true);
          console.log(loggedIn);
          console.log("data=", data["pseudo_email"]);
          //   props.userLogin(data['pseudo_email'])
          window.localStorage.setItem("pseudo_email", data["pseudo_email"]);
          //   props.setClientID(data['client_id'])
          window.localStorage.setItem("clientID", data["client_id"]);
          window.localStorage.setItem("unregistered", data["unregistered"]);
          window.localStorage.setItem("user_name", data["user_name"]);
          window.localStorage.setItem("is_admin", data["is_admin"]);
          if (data["investor_id"]) {
            window.localStorage.setItem("investor_id", data["investor_id"]);
          }
          if (data["gender_male"] !== true) {
            window.localStorage.setItem("gender_male", "false");
          } else {
            window.localStorage.setItem("gender_male", "true");
          }
          console.log(
            "gender_male = ",
            window.localStorage.getItem("gender_male")
          );
        } else {
          alert("You need to sign in with microsoft or gmail");
        }
        // this.props.navigate('/reportlist')
      })
      .catch((error) => console.error(error));
  };

  const onGoogleLoginClick = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "api/v1/auth/login/google/";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id:
        "560541008989-k0er5bb7onv6dj7d46fh05cjes2qb9p5.apps.googleusercontent.com",
      // redirect_uri: `http://localhost:8001/api/v1/auth/login/google/`,
      redirect_uri: `https://api.benchmarks.digital/api/v1/auth/login/google/`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();
    // window.localStorage.setItem('loginStatus', 'true')
    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  function acquireToken() {
    var tokenRequest = {
      scopes: ["user.read", "mail.send"],
    };

    msalInstance
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        console.log("rt=", response);
        let data = response;
        if (data) {
          console.log("work dammit");
          console.log("acc=", data["account"].userName);
          let email = data["account"].userName;
          const uploadData = new FormData();
          uploadData.append("email", data["account"].userName);
          uploadData.append("access_token", data["accessToken"]);
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/login/ms/`, {
            method: "POST",
            body: uploadData,
          })
            .then((data) => data.json())
            .then((data) => {
              if (data.token) {
                onUserLoginCT(
                  data.user_name !== undefined
                    ? data.user_name
                    : email.split("@")[0],
                  email,
                  data["gender_male"] ? "M" : "F",
                  data.client_id
                );
                console.log("data=", data);
                window.localStorage.setItem("loginStatus", "true");
                window.localStorage.setItem("token", data.token);
                window.localStorage.setItem("pseudo_email", data.pseudo_email);
                window.localStorage.setItem("clientID", data.client_id);
                window.localStorage.setItem("email", email);
                window.localStorage.setItem("user_name", data.user_name);
                window.localStorage.setItem("is_admin", data.is_admin);
                if (data.investor_id) {
                  window.localStorage.setItem("investor_id", data.investor_id);
                }
                if (data["gender_male"] !== true) {
                  window.localStorage.setItem("gender_male", "false");
                } else {
                  window.localStorage.setItem("gender_male", "true");
                }
                // fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreports/?rep_id=9&client_id=${data.client_id}`, {
                //   method:'GET',
                //   headers:{
                //     'Content-Type': 'application/json',
                //   },
                // })
                // .then(res=>res.json())
                // .then(
                //   res=>{
                //     setTreeData(res)
                //     console.log(res)
                //   }
                // )

                navigate("/redirect");
              }
            })
            .catch((error) => {
              // setSignIn(false);
              alert("System Error.Contact Admin");
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log("err= ", err);
        if (err instanceof Msal.InteractionRequiredAuthError) {
          // Fallback to interactive method via redirect
          msalInstance.acquireTokenRedirect(tokenRequest);
        } else {
          console.log("Another error:", err);
        }
      });
  }

  msalInstance.handleRedirectCallback((error, loginResponse) => {
    if (error) {
      console.error(error);
      return;
    }
    // User is logged in
    if (msalInstance.getAccount()) {
      acquireToken();
    }
  });

  const onMsLoginClick = () => {
    msalInstance.loginRedirect(["user.read"]);
  };

  const handleLogin = () => {
    console.log("msalInstance = ", msalInstance);
    msalInstance.loginPopup(["user.read"]).then(function (loginResponse) {
      // the user has successfully logged in
      // you can now get the user's access token using the getAccessToken method
      console.log("lr=", loginResponse);
      if (msalInstance.getAccount()) {
        var tokenRequest = {
          scopes: ["user.read", "mail.send"],
        };
        msalInstance
          .acquireTokenSilent(tokenRequest)
          .then((response) => {
            // get access token from response
            // response.accessToken
            console.log("rt=", response);
            let data = response;
            if (data) {
              console.log("work dammit");
              console.log("acc=", data["account"].userName);
              let email = data["account"].userName;
              const uploadData = new FormData();
              uploadData.append("email", data["account"].userName);
              uploadData.append("access_token", data["accessToken"]);
              fetch(`${process.env.REACT_APP_API_ENDPOINT}/login/ms/`, {
                method: "POST",
                body: uploadData,
              })
                .then((data) => data.json())
                .then((data) => {
                  if (data.token) {
                    console.log("data=", data);
                    window.localStorage.setItem("loginStatus", "true");
                    window.localStorage.setItem("token", data.token);
                    window.localStorage.setItem(
                      "pseudo_email",
                      data.pseudo_email
                    );
                    window.localStorage.setItem("clientID", data.client_id);
                    window.localStorage.setItem("email", email);
                    window.localStorage.setItem("user_name", data.user_name);
                    if (data["gender_male"] !== true) {
                      window.localStorage.setItem("gender_male", "false");
                    } else {
                      window.localStorage.setItem("gender_male", "true");
                    }
                    navigate("/newreport");
                  } else {
                  }
                })
                .catch((error) => {
                  // setSignIn(false);
                  alert("System Error.Contact Admin");
                  console.log(error);
                });
            }
          })
          .catch((err) => {
            // could also check if err instance of InteractionRequiredAuthError if you can import the class.
            if (err.name === "InteractionRequiredAuthError") {
              return msalInstance
                .acquireTokenPopup(tokenRequest)
                .then((response) => {
                  // get access token from response
                  // response.accessToken
                })
                .catch((err) => {
                  // handle error
                });
            }
          });
      } else {
        console.log("wrong");
      }
      // msalInstance.acquireTokenSilent(params).then(response=>{
      //     console.log('at=', response.accessToken)
      // }).catch(err=>{console.log(err)})
    });
  };

  const useQuery = () => new URLSearchParams(useLocation().search);
  const newsletter_name = useQuery().get("newsletter_name") || "";
  const newsletter_month = useQuery().get("newsletter_month") || "";
  const newsletter_user = useQuery().get("newsletter_user") || "";
  const newsletter_email = useQuery().get("newsletter_email") || "";
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        newsletter_name &&
        newsletter_month &&
        newsletter_user &&
        newsletter_email
      ) {
        onNewsletterClickCT(
          newsletter_name,
          newsletter_month,
          "clicked",
          newsletter_email,
          "Signin Page"
        );
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  if (loggedIn) {
    console.log(loggedIn);
    return <Navigate to="/otp" />;
  }

  return (
    <SignIndiv>
      <Imgdiv>
        <img
          src="/Images/signin.svg"
          alt="sideimg"
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
        ></img>
      </Imgdiv>
      <RHSdiv>
        <ImgMob src="/Images/elephant_nobg_1.svg" alt="sideimg"></ImgMob>
        <Benchmarksdiv>
          <img src="/Images/BenchmarksNew.svg" alt="logo" height={"30px"}></img>
        </Benchmarksdiv>
        <Signupmiddiv>Sign In</Signupmiddiv>
        <SocialLogin>
          <button
            style={{
              width: "65%",
              height: "100%",
              border: "1px solid rgb(204, 204, 204)",
              cursor: "pointer",
              marginBottom: "2%",
            }}
            onClick={onMsLoginClick}
          >
            Continue with <img src="/Images/ms_icon.png" alt="Microsoft" />
          </button>

          <button
            style={{
              width: "65%",
              height: "100%",
              border: "1px solid rgb(204, 204, 204)",
              cursor: "pointer",
              marginBottom: "2%",
            }}
            onClick={onGoogleLoginClick}
          >
            Continue with <img src="/Images/google_icon.png" alt="Google" />
          </button>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "65%",
              height: "100%",
              marginBottom: "2%",
            }}
          >
            <span
              style={{
                flex: 1,
                height: "1px",
                backgroundColor: "rgb(204, 204, 204)",
              }}
            ></span>
            <button
              style={{
                border: "none",
                background: "none",
                margin: "0 10px",
                whiteSpace: "nowrap",
                cursor: "default",
              }}
            >
              Or
            </button>
            <span
              style={{
                flex: 1,
                height: "1px",
                backgroundColor: "rgb(204, 204, 204)",
              }}
            ></span>
          </div>
        </SocialLogin>

        <FormDiv>
          <CustomForm fields={fields} onChange={handleChange} />
          <button style={{ width: "100%" }} onClick={handleSubmit}>
            Sign In
          </button>
        </FormDiv>
      </RHSdiv>
    </SignIndiv>
  );
};

export default SignIn;

const SignIndiv = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const Imgdiv = styled.div`
  flex: 0 0 40%;
  z-index: -10;
  ${media.small`
display:none;
flex:0;
          `}
`;

const RHSdiv = styled.div`
  flex: 0 0 60%;
  z-index: 100;
  position: relative;
  ${media.small`
      flex:1;
     z-index:100;
width: 100%;
    height: 100vh;
          `}
`;

const Benchmarksdiv = styled.div`
  margin-top: 15vh;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Signupmiddiv = styled.div`
  padding: 1%;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  margin-bottom: 30px;
  ${media.small`
      font-size: 18px;
  margin-bottom: 0.5%;
    
    `}
`;

const FormDiv = styled.div`
  margin-top: 5px;
  // padding-left: 50px;
  // padding-right: 50px;
  // @media (min-width: 768px) {
  //   padding-left: 200px;
  //   padding-right: 200px;
  // }
  width: 83%;
  padding-left: 18%;
  align-items: center;
  align-content: center;
  & button {
    background-color: #0099fe;
    line-height: 40px;
    border-radius: 50px;
    border: none;
    margin-top: 20px;
    color: white;
  }
`;
const SocialLogin = styled.div`
  margin-bottom: 10px;
  /* flex-grow:1; */
  height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    background-color: white;
    border: None;
    img {
      height: 30px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  ${media.small`
        height:8vh;
   font-size:11px;
      `}
`;

const ImgMob = styled.img`
  display: none;
  ${media.small`
    z-index:-10;
display:block;
    position: absolute;
        width: 100%;
        height: 35vh;
        bottom:0;
  `}
`;
